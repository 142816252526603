import React, { Fragment } from 'react';

import { connect } from 'react-redux';

import { Row, Col, Card, Button } from 'react-bootstrap';

class Index extends React.Component {
    render () {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Welcome, {this.props.user.name}</Card.Title>
                                <Card.Text>Where would you like to go?</Card.Text>
                                <Row>
                                    {this.props.applications.map(x => (
                                        <Col sm="12" lg="4" className="mb-2">
                                            <Card className="h-100" bg="secondary">
                                                <Card.Body>
                                                    <Card.Title>{x.clientName}</Card.Title>
                                                    <Card.Text>{x.description}</Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button as="a" block href={x.uri}>Visit {x.clientName}</Button>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    applications: state.applications.applications
})

export default connect(mapStateToProps)(Index)