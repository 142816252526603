import React from 'react';

import { Card, Row, Col, Form } from 'react-bootstrap';

import { ActionButton } from '@atlas2d/webcx';

import { toast } from 'react-toastify';

import api from '../../api';

class ChangeEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null
        }
    }

    changeEmail = async () => {
        await api.post('/settings/email/change', {
            newEmail: this.state.email
        });

        toast('An email has been sent to your new email address for confirmation.');
    }

    isEmailValid = () => {
        if (!this.state.email) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title>Change Email</Card.Title>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter a new email..." onChange={(e) => this.setState({ email: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ActionButton disabled={!this.isEmailValid()} action={this.changeEmail}>Change Email</ActionButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}

export default ChangeEmail;