import React from 'react';

import { Card, Form, Button } from 'react-bootstrap';

import { ActionButton, Loading } from '@atlas2d/webcx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from 'react-toastify';

import api from '../../api';

class Connect extends React.Component {
    connections = [
        { provider: 'Discord', icon: ['fab', 'discord'] }
    ]

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            enabledConnections: []
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        this.setState({ isLoading: true });

        const { data } = await api.get('settings/connections');

        console.log(data);

        this.setState({ isLoading: false, enabledConnections: data });
    }

    isConnected = (provider) => {
        return this.state.enabledConnections.filter(x => x.loginProvider === provider).length > 0;
    }

    disconnectConnection = async (provider) => {
        await api.post('settings/connections/remove', {
            loginProvider: provider
        });

        const updatedConnections = this.state.enabledConnections.filter(x => x.loginProvider !== provider);

        this.setState({ enabledConnections: updatedConnections });

        toast(`Your ${provider} account has been disconnected!`);
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title>Connect</Card.Title>
                    <Loading isLoading={this.state.isLoading}>
                        {this.connections.map(x => (
                            <>
                                {!this.isConnected(x.provider) && (
                                    <Form action="/connect" method="post">
                                        <Form.Control type="hidden" name="Provider" value={x.provider} />
                                        <Form.Control type="hidden" name="ReturnUrl" value="/settings/connect" />

                                        <Button type="submit"><FontAwesomeIcon icon={x.icon} /> Connect {x.provider}</Button>
                                    </Form>
                                )}

                                {this.isConnected(x.provider) && (
                                    <ActionButton action={this.disconnectConnection}><FontAwesomeIcon icon={x.icon} /> Disconnect {x.provider}</ActionButton>
                                )}
                            </>
                        ))}
                    </Loading>
                </Card.Body>
            </Card>
        )
    }
}

export default Connect;