const auth = (state = {}, action) => {
    switch (action.type) {
      case 'SET_AUTHENTICATION_STATE':
        return {
            ...state,
            isAuthenticated: action.isAuthenticated
        }
      default:
        return state
    }
  }
  
  export default auth