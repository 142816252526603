import 'react-toastify/dist/ReactToastify.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ToastContainer, Slide } from 'react-toastify';

import { setUserData, setAuthenticationState, setAvailableApplications } from './actions';

import AuthorizeRoute from './components/AuthorizeRoute';

import { Layout } from './components/Layout';
import Index from './components/Index';
import SettingsIndex from './components/Settings/Index';
import SettingsChangeEmail from './components/Settings/ChangeEmail';
import SettingsChangePassword from './components/Settings/ChangePassword';
import SettingsConnect from './components/Settings/Connect';
import SettingsNotifications from './components/Settings/Notifications';

import { Mode } from './config';

import { Branding } from '@atlas2d/webcx';

import api from './api';

import './custom.css'

export class App extends Component {
    themes = {
        'Ailurus': React.lazy(() => import('./themes/DarkRed')),
        'Atlas': React.lazy(() => import('./themes/DarkBlue')),
        'EclipseOrigins': React.lazy(() => import('./themes/DarkBlue'))
    }

    constructor(props) {
        super(props);

        this.state = {
            isReady: false
        }
    }

    async componentDidMount() {
        const { data } = await api.get(`store/initial`);

        console.log(data);

        this.props.setAuthenticationState(data.isAuthenticated);

        if (data.user) {
            this.props.setUserData(data.user);
            this.props.setAvailableApplications(data.applications);
        }

        this.setState({ isReady: true });
    }

    render() {
        if (!this.state.isReady) {
            return (
                <Branding.BrandSelector mode={Mode} themes={this.themes}>
                    <div></div>
                </Branding.BrandSelector>
            )
        }

        return (
            <Branding.BrandSelector mode={Mode} themes={this.themes}>
                <Layout>
                    <AuthorizeRoute exact path='/' component={Index} />
                    <AuthorizeRoute exact path='/settings/email/change' component={SettingsChangeEmail} />
                    <AuthorizeRoute exact path='/settings/password/change' component={SettingsChangePassword} />
                    <AuthorizeRoute exact path='/settings/connect' component={SettingsConnect} />
                    <AuthorizeRoute exact path='/settings/notifications' component={SettingsNotifications} />
                    <AuthorizeRoute exact path='/settings' component={SettingsIndex} />
                </Layout>   
                <ToastContainer transition={Slide} limit={3} pauseOnFocusLoss={false} />
            </Branding.BrandSelector>
            
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setUserData: user => dispatch(setUserData(user)),
    setAuthenticationState: isAuthenticated => dispatch(setAuthenticationState(isAuthenticated)),
    setAvailableApplications: applications => dispatch(setAvailableApplications(applications))
})

export default connect(undefined, mapDispatchToProps)(App);
