import React from 'react'

import { connect } from 'react-redux';

import { Component } from 'react'
import { Route } from 'react-router-dom'

class AuthorizeRoute extends Component {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const { component: Component, ...rest } = this.props;
        return <Route {...rest}
            render={(props) => {
                if (this.props.isAuthenticated) {
                    return <Component {...props} />
                } else {
                    const returnUrl = window.location.pathname;
                    const redirectUrl = `/login?returnUrl=${encodeURIComponent(returnUrl)}`;

                    window.location.replace(redirectUrl);

                    return null;
                }
            }} />
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(AuthorizeRoute);