import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import api from '../api';

export class LoginMenu extends Component {
    logOut = async () => {
        await api.post('/logout', {}, {
            baseURL: '/'
        });

        window.location.reload();
    }

    render() {
        if (!this.props.isAuthenticated) {
            return this.anonymousView('/register', '/login');
        } else {
            return this.authenticatedView(this.props.user.name, '/settings', '');
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavDropdown title={userName} id="profile-menu">
                <NavDropdown.Item as={Link} to={profilePath}><i className="fas fa-cogs"></i> Settings</NavDropdown.Item>
                <NavDropdown.Item onClick={this.logOut}>Logout</NavDropdown.Item>
            </NavDropdown>
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <Nav.Item>
                <Nav.Link as={Link} to={registerPath}>Register</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={loginPath}>Login</Nav.Link>
            </Nav.Item>
        </Fragment>);
    }
}

const mapStateToProps = state => ({
    user: state.user,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(LoginMenu);