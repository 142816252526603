import React from 'react';

import { connect } from 'react-redux';

import { Navbar, Nav, Container } from 'react-bootstrap';

import LoginMenu from './LoginMenu';

import { Link } from 'react-router-dom';

import AilurusLogo from '../images/Ailurus.png'

import { Mode } from '../config'

class NavBar extends React.Component {
    renderAppBrand = () => {
        switch (Mode) {
            case 'Ailurus': {
                    return (
                        <Navbar.Brand as={Link} to="/">
                            <img src={AilurusLogo} alt="logo" height="32" />
                        </Navbar.Brand>
                    )
                }
            default: {
                    return (
                        <Navbar.Brand as={Link} to="/">Home</Navbar.Brand>
                    )
                }
        }
        
    }

    render() {
        return (
            <header>
                <Navbar variant="dark" bg="dark" expand="lg" sticky="top">
                    <Container>
                        {this.renderAppBrand()}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                            </Nav>
                            <Nav>
                                <LoginMenu />
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user
})

export default connect(mapStateToProps)(NavBar);