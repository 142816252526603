const applications = (state = [], action) => {
    switch (action.type) {
      case 'SET_AVAILABLE_APPLICATIONS':
        return {
            applications: action.applications
        }
      default:
        return state
    }
  }
  
  export default applications