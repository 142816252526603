import React from 'react';

import { Link } from 'react-router-dom';

import { Button, CardDeck, Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Index extends React.Component {
    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title as="h4">Account Settings</Card.Title>
                    <CardDeck>
                        <Card bg="secondary">
                            <Card.Body>
                                <Card.Title>Email</Card.Title>
                                <Card.Text>Update the email used on your account.</Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Button as={Link} to="/settings/email/change" block><FontAwesomeIcon icon="envelope" /> Change Email</Button>
                            </Card.Footer>
                        </Card>
                        <Card bg="secondary">
                            <Card.Body>
                                <Card.Title>Change Password</Card.Title>
                                <Card.Text>Change your account password.</Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Button as={Link} to="/settings/password/change" block><FontAwesomeIcon icon="key" /> Change Password</Button>
                            </Card.Footer>
                        </Card>
                        <Card bg="secondary">
                            <Card.Body>
                                <Card.Title>Notifications</Card.Title>
                                <Card.Text>Configure what type of notifications to receive, and how they are delivered.</Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Button as={Link} to="settings/notifications" block><FontAwesomeIcon icon="bullhorn" /> Configure</Button>
                            </Card.Footer>
                        </Card>
                        <Card bg="secondary">
                            <Card.Body>
                                <Card.Title>Connect</Card.Title>
                                <Card.Text>Connect your account with external providers.</Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Button as={Link} to="/settings/connect" block><FontAwesomeIcon icon="sign-in-alt" /> Connect</Button>
                            </Card.Footer>
                        </Card>
                    </CardDeck>
                </Card.Body>
            </Card>
        )
    }
}

export default Index;