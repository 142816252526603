export const setUserData = (user) => ({
    type: 'SET_USER_DATA',
    user: user
})

export const setAuthenticationState = (isAuthenticated) => ({
    type: 'SET_AUTHENTICATION_STATE',
    isAuthenticated: isAuthenticated
})

export const setAvailableApplications = (applications) => ({
    type: 'SET_AVAILABLE_APPLICATIONS',
    applications: applications
})