import React from 'react';

import { Card, Row, Col, Form, ListGroup } from 'react-bootstrap';

import { Loading } from '@atlas2d/webcx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import api from '../../api';

class Connect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            providers: {},
            sources: []
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        this.setState({ isLoading: true });

        const { data } = await api.get('settings/notifications');

        this.setState({ isLoading: false, providers: data.sendNotifications, sources: data.sources });
    }

    updateProvider = async (provider, value) => {
        await api.post('settings/notifications/providers', {
            provider: provider,
            state: value
        })
    }

    updateSource = async (source, value) => {
        await api.post('settings/notifications/sources', {
            source: source.clientId,
            state: value
        })
    }

    render() {
        return (
            <Row>
                <Col md="6" sm="12">
                    <Card>
                        <Card.Body>
                            <Card.Title>Send notifications through...</Card.Title>
                            <Loading isLoading={this.state.isLoading}>
                                <ListGroup>
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        <span><FontAwesomeIcon icon="envelope" /> Email</span>
                                        <Form.Switch defaultChecked={this.state.providers.email} id="email-switch" label="" aria-label="Email" onChange={(e) => this.updateProvider('email', e.target.checked)} />
                                    </ListGroup.Item>
                                    {/* <ListGroup.Item className="d-flex justify-content-between">
                                        <span><FontAwesomeIcon icon="mobile" /> SMS</span>
                                        <Form.Switch id="sms-switch" disabled label=""></Form.Switch>
                                    </ListGroup.Item> */}
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        <span><FontAwesomeIcon icon={['fab', 'discord']} /> Discord</span>
                                        <Form.Switch defaultChecked={this.state.providers.discord} id="discord-switch" label="" aria-label="Discord" onChange={(e) => this.updateProvider('discord', e.target.checked)} />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Loading>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6" sm="12">
                    <Card>
                        <Card.Body>
                            <Card.Title>Enable notifications for...</Card.Title>
                            <Loading isLoading={this.state.isLoading}>
                                <ListGroup>
                                    {this.state.sources.map(x => (
                                        <ListGroup.Item className="d-flex justify-content-between">
                                            <span>{x.clientName}</span>
                                            <Form.Switch defaultChecked={x.state} id={x.clientId + '-switch'} label="" onChange={(e) => this.updateSource(x, e.target.checked)} />
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Loading>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default Connect;