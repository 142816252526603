import React from 'react';

import { Card, Row, Col, Form } from 'react-bootstrap';

import { ActionButton } from '@atlas2d/webcx';

import { toast } from 'react-toastify';

import api from '../../api';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPassword: null,
            newPassword: null,
            newPasswordConfirmation: null
        }
    }

    changePassword = async () => {
        await api.post('/settings/password/change', {
            password: this.state.currentPassword,
            newPassword: this.state.newPassword
        });

        toast('Your password has been updated!');
    }

    canChangePassword = () => {
        if (!this.state.currentPassword || !this.state.newPassword || !this.state.newPasswordConfirmation) {
            return false;
        }

        if (this.state.newPassword !== this.state.newPasswordConfirmation) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title>Change Password</Card.Title>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => this.setState({ currentPassword: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => this.setState({ newPassword: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => this.setState({ newPasswordConfirmation: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ActionButton disabled={!this.canChangePassword()} action={this.changePassword}>Change Password</ActionButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}

export default ChangePassword;